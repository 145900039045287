import { useAuth0 } from "@auth0/auth0-react";

import Container from 'react-bootstrap/Container';
import { useNavigate } from "react-router-dom";

const LoginRedirect = () => {
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    if (isAuthenticated) {
        navigate("/tenants")
    } else {
        loginWithRedirect()
    }
    return (<Container></Container>)
}

export default LoginRedirect;
