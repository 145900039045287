import { useAuth0 } from "@auth0/auth0-react";

import Container from 'react-bootstrap/Container';
import Tenants from "./tenants";

const Landing = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    return (
        <Container>
            {(!isAuthenticated || isLoading) &&
                <Container>
                    <h1 className="header">
                        Say goodbye to messy data
                    </h1>
                    <h3 className="header muted-text">
                        Where data entry becomes effortless, fast, and secure - straight from Excel.
                    </h3>
                </Container>
            }
            {(isAuthenticated && !isLoading) &&
                <Tenants />

            }
        </Container >
    );
}

export default Landing;
