import { Auth0Provider } from "@auth0/auth0-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./components/landing";
import Tenants from "./components/tenants";
import LoginRedirect from "./components/login_redirect";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Auth0Provider
    domain="dev-82gwovly372z57nw.us.auth0.com"
    clientId="VtabjvFYWggQOPT9lWkbul1J9n547HIX"
    redirectUri={window.location.origin}
  >
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="" element={<Landing />} />
            <Route path="login" element={<LoginRedirect />} />
            <Route path="tenants" element={<Tenants />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Auth0Provider>
);

