import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Spinner, Toast } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Backend } from '../App';
import GetStarted from "./get_started";


export interface ITenant {
    id: string;
    name: string;
    tokens: string[];
}

const Tenants = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [tenantData, setTenantData] = useState<ITenant[]>([]);
    const [selectedTenantIndex, setSelectedTenantIndex] = useState(0);
    const [name, setName] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const [showCreateModal, setCreateModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    // this effect needs to stay here as the ControlCenter is the actual component being loaded
    useEffect(() => {
        setLoading(true);
        setShowErrorAlert(false);
        getAccessTokenSilently({
            audience: "api.cdm.wtf/tenants"
        }).then((token: string) =>
            fetch(`${Backend}/v1/tenant`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    setLoading(false);
                    if (!response.ok) {
                        return response.json().then(e => new Error(e["message"]))
                    }
                    return response.json();
                })
                .then(data => {
                    if (data instanceof Error) {
                        throw data;
                    }

                    // pivot this back into an array for react
                    const tx: ITenant[] = data.tenants.map((d: any) => d as ITenant);
                    setTenantData(tx);
                }).catch(e => {
                    console.log(e);
                    setErrorMessage(`${e.message} - could not retrieve campaigns, please try again later.`);
                    setShowErrorAlert(true);
                }));
        // eslint-disable-next-line
    }, [getAccessTokenSilently, user?.sub]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setCreateModal(false);
        let token = await getAccessTokenSilently({
            audience: "api.cdm.wtf/tenants"
        });
        var raw = JSON.stringify({
            "name": name,
        });

        let fetchResult = await fetch(`${Backend}/v1/tenant`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }, body: raw
        });

        let body = await fetchResult.json();
        if (!fetchResult.ok) {
            setErrorMessage("Could not create tenant, error was: " + body.message);
            setShowErrorAlert(true);
        } else {
            var tx: ITenant = {
                id: body.tenant.id,
                name: body.tenant.name,
                tokens: body.tokens
            }
            setTenantData((tenantData: ITenant[]) => {
                return [...tenantData, tx]
            });
            setName("");
        }
    }

    return (
        <Container>
            <Row>

                <Modal show={showCreateModal} onHide={() => setCreateModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Tenant</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Tenant Name</Form.Label>
                            <Form.Control type="text" maxLength={30} minLength={5}
                                value={name}
                                onChange={event => {
                                    setName(event.target.value)
                                }} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setCreateModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" onClick={handleSubmit}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Row>
                    <Container>
                        {showErrorAlert &&
                            <Toast
                                bg="danger"
                                onClose={() => setShowErrorAlert(false)}
                                show={showErrorAlert}>
                                <Toast.Body className="text-white">{errorMessage}</Toast.Body>
                            </Toast>
                        }
                    </Container>
                </Row>

                <Col md="auto">
                    <Row>
                        <Button variant="outline-primary" onClick={() => setCreateModal(true)}>Create New Tenant</Button>
                    </Row>

                    {loading &&
                        <Row className="row-loading-spinner">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Row>
                    }
                    {!loading &&
                        tenantData.map(function (v: ITenant, i: number) {
                            return <Row key={"trow/" + v.id}>
                                <Card key={"tcard/" + v.id}
                                    className={i === selectedTenantIndex ? "selected-tenant" : ""}
                                    bg={i === selectedTenantIndex ? "success" : "light"}
                                    text={i === selectedTenantIndex ? "light" : "dark"}
                                    onClick={() => setSelectedTenantIndex(i)} >
                                    <Card.Body>
                                        <Card.Title>{v.name ?? v.id}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Row>
                        })
                    }

                </Col>

                <Col>
                    {tenantData.length > 0 &&
                        <GetStarted tenant={tenantData[selectedTenantIndex]} />
                    }
                </Col>
            </Row>
        </Container >
    );
}

export default Tenants;
