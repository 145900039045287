import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ITenant } from "./tenants";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Swagger from './swagger_ui';
import { useState } from 'react';
import { Link } from 'react-router-dom';


const GetStarted = ({ tenant }: { tenant: ITenant }) => {
    const apiToken = tenant.tokens[0];
    const curlCode = `curl --header 'X-cdmwtf-key: ${apiToken}' --request GET 'https://api.cdm.wtf/v1/collect/Building'`;
    const [key, setKey] = useState('console');


    return (
        <Container>
            <h2 className="setup-header">
                Let's get started 🎉
            </h2>

            <p>
                The first step to get up and running with CDM is to get data into your project.
            </p>

            <h4>
                Implementation Options
            </h4>

            <div>
                There are many different ways to access your CDM, all of them require your API token:

                <div className='apitoken-container'>
                    <code className='apitoken-box'>
                        {apiToken}
                    </code>

                    <CopyToClipboard text={apiToken}>
                        <Button><FaCopy /></Button>
                    </CopyToClipboard>
                </div>
            </div>

            <h4>
                <Tabs
                    defaultActiveKey="console"
                    className="mb-3 implementation-pills"
                    variant="pills"
                    activeKey={key}
                    onSelect={(k) => setKey(k ?? "console")}>
                    <Tab eventKey="console" title="Curl">
                        <SyntaxHighlighter
                            language="shell"
                            wrapLines={true}
                            wrapLongLines={true}
                            style={a11yDark}
                            showLineNumbers={true}>
                            {curlCode}
                        </SyntaxHighlighter>

                    </Tab>
                    <Tab eventKey="swagger" title="Swagger">
                        {key === "swagger" && <Swagger tenant={tenant} />}
                    </Tab>
                    <Tab eventKey="excel" title="Excel">
                        <Link to="/install.ps1" target="_blank" download>Download the installer (Windows)</Link>
                    </Tab>
                </Tabs>
            </h4>



        </Container >
    );
}

export default GetStarted;
