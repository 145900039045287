import { useAuth0 } from "@auth0/auth0-react";
import { Container } from "react-bootstrap";
import LoginButton from "./login_button";
import LogoutButton from "./logout_button";

const Profile = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (
        <div>
            {isAuthenticated && user && (
                <Container>
                    <div>
                        <h5>{user.name ?? user.email}</h5>
                        <LogoutButton />
                    </div>
                </Container>
            )}

            {!isAuthenticated && (
                <LoginButton />
            )}

        </div>
    );
};

export default Profile;